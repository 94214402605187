import "./styles.less";

interface pageTitleProps {
  title: string;
  logoUrl: string | null | undefined;
}

type Props = pageTitleProps;

const PageTitle: React.FC<Props> = (props) => {
  let logoUrl: string | null | undefined = props.logoUrl;
  if (logoUrl === "" || logoUrl === null || logoUrl === undefined) {
    logoUrl = "";
  }
  return (
    <div className={"title-grid-container "}>
      <div className={"title-page-text"}>{props.title}</div>
      <div
        className="orgLogo"
        style={{ backgroundImage: `url(${logoUrl})` }}
      ></div>
    </div>
  );
};

export default PageTitle;
