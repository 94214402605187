import { Card } from "antd";
import UpdateIcon from "../../img/alert-circle-blue.svg";
import FixIcon from "../../img/alert-circle-red.svg";
import "./styles.less";

const OvenCard: React.FC<any> = (props) => {
  const { ovenData } = props;

  const getWarningIcon = (ovenData: any) => {
    if (ovenData?.errors > 0) return FixIcon;
    else if (ovenData?.updates > 0) return UpdateIcon;
    else return "";
  };
  return (
    <Card
      onClick={props.onClick}
      style={{
        width: "100%",
        height: "166px",
        background: "#EAEAEA 0% 0% no-repeat padding-box",
        borderRadius: "12px",
        boxShadow: "none",
        cursor: "pointer",
      }}
      className={props.active ? "oven-card-active" : ""}
    >
      {" "}
      {props.showWarning ? (
        <img
          src={getWarningIcon(ovenData)}
          style={{
            height: 36,
            backgroundColor: "white",
            position: "absolute",
            top: -15,
            right: -15,
            borderRadius: 36,
          }}
        />
      ) : (
        ""
      )}
      <span className="mini-grid-container-main">
        <span className="oven-card-name">{ovenData.name}</span>
        <span className="oven-card-model">{ovenData.model}</span>
      </span>
      <div className="card-data-content">
        <span className="mini-grid-container">
          <span className="left-float">Cooks</span>
          <span className="right-float">{ovenData.cooks}</span>
        </span>
        <span className="mini-grid-container">
          <span className="left-float">Status</span>
          <span className="right-float">{ovenData.ovenStatus}</span>
        </span>
        <span className="mini-grid-container">
          <span className="left-float">Errors</span>
          <span className="right-float">{ovenData.errors}</span>
        </span>
        <span className="mini-grid-container">
          <span className="left-float">Hours Of Operation</span>
          <span className="right-float">{ovenData.hoursOperational}</span>
        </span>
      </div>
    </Card>
  );
};

export default OvenCard;
