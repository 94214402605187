import React, { useEffect, useState, useContext } from "react";
import { Checkbox, Card, Row, Col, message } from "antd";
import { useNavigate } from "react-router-dom";

import { context } from "./appLayout";
import OvenCard from "../components/oven/ovenCard";
import PageTitle from "../components/common/pageTitle";
import DropDowns, { selection } from "../components/common/dropDowns";
import { getOvens } from "../api/portalData";
import { DropdownResponse } from "../api/responses";
import OvenPopup from "../components/oven/ovenPopup";
import Loader from "../components/utils/Loader";
import { roles } from "../config/types";

import addIcon from "../img/add-icon.svg";
import "./styles/ovens.less";

interface Props {
  logoUrl: string;
  dropdownData: DropdownResponse;
  orgId: number;
  setOrgId: React.Dispatch<React.SetStateAction<number>>;
  isDropdownLoading: boolean;
}
const OvenComponent: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { logoUrl, dropdownData, orgId, setOrgId, isDropdownLoading } = props;

  const [ovenData, setOvenData] = useState<any>([]);
  const [cardData, setCardData] = useState<any | null>(null);
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [dropdown, setDropdown] = useState<selection>({
    org: orgId,
    state: [],
    city: [],
    store: [],
  });
  const abortController = new AbortController();
  const role = useContext(context);

  function needsUpdates(e: Event): void {
    // This is the checkbox, currently does nothing
    console.log(`checked = ${(e.target as HTMLInputElement).checked}`);
  }
  const AddOvenCardDisplay = (props: { role: roles | null }) => {
    if (props.role === "manager") {
      return <></>;
    } else {
      return (
        <Col xs={24} sm={24} md={12} lg={8}>
          <Card className="oven-add-btn" onClick={() => navigate("/oven/new")}>
            <img src={addIcon} alt="+" className="add-icon" />
          </Card>
        </Col>
      );
    }
  };

  const displayNCards = (data: React.PropsWithChildren<any>) => {
    const ovenElements = [];
    for (let i = 0; i < data.length; i++) {
      ovenElements.push(
        <Col xs={24} sm={24} md={12} lg={8} key={i + "a"}>
          <OvenCard
            key={i}
            ovenData={data[i]}
            onClick={() => setCardData(data[i])}
          />
        </Col>
      );
    }

    return ovenElements;
  };

  const onSelectionChange = (
    value: string[] | number | undefined,
    valueType: string
  ) => {
    let selection = { ...dropdown };
    if (valueType === "org") {
      setOrgId(value as number);
      selection = { org: value as number, state: [], city: [], store: [] };
    } else if (valueType === "state") {
      selection = {
        ...selection,
        store: [],
        city: [],
        state: value as string[],
      };
    } else if (valueType === "city") {
      selection = {
        ...selection,
        store: [],
        city: value as string[],
      };
    } else if (valueType === "store") {
      selection = {
        ...selection,
        store: value as string[],
      };
    }
    setDropdown(selection);
  };

  const getOvensOnChange = (selection: selection, abortController: any) => {
    getOvens(selection, abortController.signal)
      .then((resp) => {
        setCardLoading(false);
        setOvenData(resp);
      })
      .catch((error) => {
        if (error.message != "AbortError") {
          setOvenData([]); // <-- this is being called after component unmounts!
          setCardLoading(false);
          message.error(error.message);
        }
      });
  };
  useEffect(() => {
    setCardLoading(true);
    getOvensOnChange(dropdown, abortController);

    return () => {
      abortController.abort();
    };
  }, [dropdown]);

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <PageTitle title="Ovens" logoUrl={logoUrl} />
      <DropDowns
        isDropdownLoading={isDropdownLoading}
        dropDownData={dropdownData}
        dropdown={dropdown}
        page="ovens"
        onChange={onSelectionChange}
      />
      {/* <div className="updates-checkbox-div">
        <Checkbox onChange={() => needsUpdates}>Needs Updates</Checkbox>
      </div> */}

      <Row gutter={[16, 16]} className="oven-cards-wrapper">
        {cardLoading ? (
          <Loader />
        ) : (
          [
            <AddOvenCardDisplay role={role} key={10000} />,
            displayNCards(ovenData),
          ]
        )}
      </Row>
      {cardData != null ? (
        <OvenPopup
          ovenData={cardData}
          onClose={() => setCardData(null)}
        ></OvenPopup>
      ) : (
        ""
      )}
    </div>
  );
};

export default OvenComponent;
