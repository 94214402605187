import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Form, Input, message, Row, Spin } from "antd";

import { context } from "./appLayout";

import BackButton from "../components/common/backButton";
import { DropdownResponse } from "../api/responses";
import SingleDropdown from "../components/common/singleDropDown";
import {
  IDropdownOptions,
  parseFilterResponse,
  selection,
} from "../components/common/dropDowns";
import { DOUBLE_OVEN_MODELS, OVEN_MODELS } from "../config/constants";
import {
  createOven,
  deleteOven,
  editOvenDetails,
  getOvenDetails,
} from "../api/portalData";
import { OvenInput, OvenInput2 } from "../config/types";
import ConfirmButton from "../components/common/confirmButton";

interface Props {
  mode: "Create" | "Edit";
  logoUrl: string;
  dropdownData: DropdownResponse;
  orgId: number;
  setOrgId: React.Dispatch<React.SetStateAction<number>>;
}

const CreateOven = (props: Props): JSX.Element => {
  const [form] = Form.useForm();

  const { mode } = props;

  const role = useContext(context);
  const navigate = useNavigate();
  const { dropdownData, orgId, setOrgId } = props;
  const { ovenId } = useParams<{ ovenId: string }>();

  const [currentOptions, setCurrentOptions] = useState<IDropdownOptions>({
    orgs: [],
    states: [],
    cities: [],
    stores: [],
  });
  const [dropdown, setDropdown] = useState<selection>({
    org: orgId,
    state: [],
    city: [],
    store: [],
  });
  const [model, setModel] = useState<string>(OVEN_MODELS[0]);
  const [loading, setLoading] = useState<boolean>(false);

  const getOvenDetailsCall = async (ovenId: string) => {
    try {
      setLoading(true);
      const ovenDetails = await getOvenDetails(ovenId);
      // Setting the org on page refresh
      if (orgId == 0) setOrgId(ovenDetails.store.orgId);
      // Setting the state and city as it it outside the form
      setDropdown({
        ...dropdown,
        state: [ovenDetails.store.state],
        city: [`${ovenDetails.store.city},${ovenDetails.store.state}`],
      });

      setModel(ovenDetails.oven.model);

      form.setFieldsValue({
        ...ovenDetails.oven,
        store: ovenDetails.store.id,
      });
    } catch (err) {
      message.error(err + "");
    } finally {
      setLoading(false);
    }
  };

  const deleteOvenCall = async () => {
    try {
      setLoading(true);
      if (ovenId) {
        const resp = await deleteOven(ovenId);
        message.success(resp);
        navigate("/");
      }
    } catch (err) {
      message.error(err + "");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const options = parseFilterResponse(dropdownData, dropdown);
    setCurrentOptions(options);
    if (mode === "Create")
      form.setFieldsValue({
        orgId: orgId != 0 ? orgId : undefined,
        model: OVEN_MODELS[0],
      });
    else {
      if (ovenId != undefined) getOvenDetailsCall(ovenId);
    }
  }, []);

  useEffect(() => {
    const options = parseFilterResponse(dropdownData, dropdown);
    setCurrentOptions(options);
  }, [dropdownData, dropdown]);

  const handleChange = (
    value: string | number | undefined,
    valueType: string
  ) => {
    let formData = { ...dropdown };
    if (valueType === "org") {
      setOrgId(value as number);
      // Resetting the state
      formData = { ...formData, state: [], city: [], store: [] };
    } else if (valueType === "state") {
      formData = {
        ...formData,
        store: [],
        city: [],
        state: value ? [value as string] : [],
      };
    } else if (valueType === "city") {
      formData = {
        ...formData,
        store: [],
        city: value ? [value as string] : [],
      };
    }
    setDropdown(formData);

    // Clearing store value
    form.setFieldsValue({
      store: "",
    });
  };
  const onFinish = async (values: OvenInput | OvenInput2) => {
    setLoading(true);
    try {
      if (mode == "Create") {
        const result = await createOven(values);
        message.success(
          "Oven(s) Created. Config file(s) will be downloded automatically."
        );
        result.forEach((e) => {
          const downloadEle = document.createElement("a");
          downloadEle.setAttribute("href", e[1]);
          downloadEle.setAttribute("download", `config_${e[0]}.json`);
          downloadEle.click();
          downloadEle.remove();
        });
        form.resetFields();
        navigate("/");
      } else {
        const result = await editOvenDetails(ovenId as string, {
          ...values,
          orgId,
        });
        message.success(result);
      }
    } catch (err) {
      message.error(`Failed to ${mode} Oven -   ${err}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Spin spinning={loading}>
        <BackButton onBack={() => navigate(-1)} />
        <div className="page-title">
          {mode == "Create" ? "Create New Oven" : "Edit Oven"}
        </div>
        <Row className="form-wrapper">
          <Form
            name="oven"
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            className="main-form"
            form={form}
          >
            {role === "admin" ? (
              <>
                <div className="dropdown-title">Company</div>
                {mode != "Edit" ? (
                  <Form.Item
                    name="orgId"
                    rules={[{ required: true, message: "" }]}
                  >
                    <SingleDropdown
                      value={orgId}
                      valueType="org"
                      options={currentOptions.orgs}
                      onChange={handleChange}
                    />
                  </Form.Item>
                ) : (
                  <div style={{ padding: "6px 0px", fontSize: 16 }}>
                    {
                      currentOptions.orgs.filter(
                        (org) => parseInt(org.id) === orgId
                      )[0]?.name
                    }
                  </div>
                )}
              </>
            ) : (
              ""
            )}
            <div className="dropdown-title">State</div>

            <SingleDropdown
              allowClear
              value={dropdown.state[0] ?? ""}
              valueType="state"
              options={currentOptions.states}
              onChange={handleChange}
            />

            <div style={{ marginTop: 12 }} className="dropdown-title">
              City
            </div>

            <SingleDropdown
              allowClear
              value={dropdown.city[0] ?? ""}
              valueType="city"
              options={currentOptions.cities}
              onChange={handleChange}
            />

            <div style={{ marginTop: 12 }} className="dropdown-title">
              Store
            </div>
            <Form.Item name="store" rules={[{ required: true, message: "" }]}>
              <SingleDropdown
                value=""
                valueType="store"
                options={currentOptions.stores}
                onChange={() => ""} //Do Nothing
              />
            </Form.Item>

            <div className="dropdown-title">Model</div>
            <Form.Item name="model" rules={[{ required: true, message: "" }]}>
              {mode == "Create" ? (
                <SingleDropdown
                  value=""
                  valueType="model"
                  options={OVEN_MODELS.concat(DOUBLE_OVEN_MODELS).map((e) => ({
                    id: e,
                    name: e,
                  }))}
                  onChange={(value) => setModel(value as string)}
                />
              ) : (
                <Input
                  disabled
                  bordered={false}
                  style={{ color: "black", padding: 0, fontSize: 16 }}
                />
              )}
            </Form.Item>

            {DOUBLE_OVEN_MODELS.includes(model) ? (
              <>
                <div className="dropdown-title">Top Oven Name</div>
                <Form.Item
                  name="top_name"
                  rules={[{ required: true, message: "" }]}
                >
                  <Input placeholder="Oven Name - Top" />
                </Form.Item>
                <div className="dropdown-title">Bottom Oven Name</div>
                <Form.Item
                  name="bottom_name"
                  rules={[{ required: true, message: "" }]}
                >
                  <Input placeholder="Oven Name - Bottom" />
                </Form.Item>
              </>
            ) : (
              <>
                <div className="dropdown-title">Name</div>
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: "" }]}
                >
                  <Input />
                </Form.Item>
              </>
            )}

            <div className="dropdown-title">Serial Number</div>
            <Form.Item
              name="serialNo"
              rules={[{ required: true, message: "" }]}
            >
              <Input
                disabled={mode === "Edit"}
                bordered={mode === "Create"}
                style={
                  mode == "Create"
                    ? {}
                    : { color: "black", padding: 0, fontSize: 16 }
                }
              />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }}>
              <div className="btn-grp nowrap">
                {mode === "Create" ? (
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                ) : (
                  <>
                    <Button type="primary" htmlType="submit">
                      Save Changes
                    </Button>
                    <ConfirmButton
                      buttonText="Delete Oven"
                      onOk={() => deleteOvenCall()}
                      warningText="Are you sure you want to delete this oven?"
                      secondaryText=" You cannot undo this action."
                      okText="Delete"
                    />
                  </>
                )}
              </div>
            </Form.Item>
          </Form>
        </Row>
      </Spin>
    </>
  );
};

export default CreateOven;
