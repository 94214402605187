import { message, Tabs } from "antd";

import SettingsAccount from "../components/settings/settingsAccount";
import SettingsNotifications from "../components/settings/settingsNotifications";
import PSWrapper from "../components/common/psWrapper";
import { useEffect, useState } from "react";
import { editUser, getUserDetails } from "../api/portalData";
import Loader from "../components/utils/Loader";

const SettingsMenu: React.FC = () => {
  const { TabPane } = Tabs;
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [profileData, setProfileData] = useState<any | null>(null);
  const getProfileDataCall = async () => {
    const result = await getUserDetails();
    const items = result.details;
    setProfileData({
      ...items,
      email: items.emails[0],
    });
  };
  useEffect(() => {
    getProfileDataCall();
  }, []);

  const editUserCall = async () => {
    try {
      const resp = await editUser(profileData);
      setUpdateTrigger(false);
      await getProfileDataCall();
      message.success("Your information is now up to date");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (updateTrigger) editUserCall();
  }, [profileData]);

  const onSaveChangesClicked = () => {
    setUpdateTrigger(true);
  };

  return (
    <PSWrapper
      title="Settings"
      buttonAction={onSaveChangesClicked}
      buttonTitle="Save Changes"
    >
      <Tabs defaultActiveKey="1" style={{ minHeight: 400 }}>
        <TabPane
          tab={
            <span
              style={{ font: "normal normal normal 24px/32px 'Questrial'" }}
            >
              Account
            </span>
          }
          key="1"
        >
          {profileData != null ? (
            <SettingsAccount
              updateTrigger={updateTrigger}
              setUpdateTrigger={setUpdateTrigger}
              setProfileData={setProfileData}
              getProfileData={getProfileDataCall}
              profileData={profileData}
            />
          ) : (
            <Loader />
          )}
        </TabPane>
        <TabPane
          tab={
            <span
              style={{ font: "normal normal normal 24px/32px 'Questrial'" }}
            >
              Notifications
            </span>
          }
          key="2"
        >
          <SettingsNotifications profileData={profileData} />
        </TabPane>
      </Tabs>
    </PSWrapper>
  );
};
export default SettingsMenu;
