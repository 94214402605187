import { Layout, message, Spin } from "antd";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import "react-tiny-fab/dist/styles.css";
import { useMsal } from "@azure/msal-react";

import MenuComponent from "./menu";
import OvenComponent from "./ovens";
import Locations from "./locations";
import UploadRecipe from "./uploadRecipe";
import StoreView from "./storeView";
import CreateOven from "./createOven";
import Profile from "./profile";
import Settings from "./settings";

import {
  CREATE_OVEN_URL,
  EDIT_OVEN_URL,
  PROFILE_URL,
  SETTINGS_URL,
} from "../config/constants";
import { getUserDetails, getDropdownData } from "../api/portalData";
import { DropdownResponse } from "../api/responses";
import EditLocation from "./editLocations";
import { roles } from "../config/types";

import "./styles/appLayout.less";

const { Header, Content } = Layout;

// interface MenuItems {
//   menu: string[];
//   menuPath: string[];
// }

export const context = React.createContext<null | roles>(null);

const AppLayout: React.FC = () => {
  const [currentRole, setcurrentRoles] = useState<roles | null>(null);
  const [userDisplayDetails, setUserDisplayDetails] = useState<any>(null);
  const [dropdownData, setdropdownData] = useState<DropdownResponse>({
    orgs: [],
    states: {},
    stores: [],
  });
  const [isDropdownLoading, setIsDropdownLoading] = useState(false);
  const [orgId, setOrgId] = useState<number>(0);
  const [logoUrl, setLogoUrl] = useState<string>("");

  const { instance } = useMsal();
  useEffect(() => {
    instance.handleRedirectPromise().then((response): void => {
      if (response != null) {
        //Redirecting to the page first time after login
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const account: any = response.account;
        setcurrentRoles(account?.idTokenClaims?.extension_Role);
      } //User refreshes the page
      else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const account: any = instance.getActiveAccount();
        setcurrentRoles(account?.idTokenClaims?.extension_Role);
      }
    });
  }, []);

  useEffect(() => {
    if (currentRole != null) {
      getUserDetails()
        .then((resp: any) => setUserDisplayDetails(resp))
        .catch((err) => {
          setUserDisplayDetails({ menu: [], menuPath: [] });
          message.error(err.message);
        });
    }
  }, [currentRole]);

  // Initial API call to get dropdown Data
  useEffect(() => {
    setIsDropdownLoading(true);
    getDropdownData(0)
      .then((items) => {
        if (items.orgs.length == 1) setLogoUrl(items.orgs[0].logoUrl);
        setdropdownData(items);
        setIsDropdownLoading(false);
      })
      .catch((err) => message.error(err.message));
  }, []);

  const refreshDropdown = () => {
    setIsDropdownLoading(true);
    getDropdownData(orgId)
      .then((items) => {
        if (items.orgs.length == 1) setLogoUrl(items.orgs[0].logoUrl);
        if (currentRole === "admin" && orgId != 0)
          items.orgs = dropdownData.orgs;
        setdropdownData(items);
        setIsDropdownLoading(false);
      })
      .catch((err) => message.error(err.message));
  };

  // API Call when organization is Changed
  useEffect(() => {
    orgId != 0 && refreshDropdown();
  }, [orgId]);

  if (userDisplayDetails === null) {
    return (
      <div className="loading-div">
        <Spin />
      </div>
    );
  } else {
    return (
      <context.Provider value={currentRole}>
        <Router>
          <Layout style={{ height: "100vh" }}>
            <Header className="ovention-header" style={{ zIndex: 60 }}>
              <MenuComponent {...userDisplayDetails} />
            </Header>
            <Content className="main-content">
              <Routes>
                <Route
                  path="/"
                  element={
                    <OvenComponent
                      logoUrl={logoUrl}
                      isDropdownLoading={isDropdownLoading}
                      dropdownData={dropdownData}
                      orgId={orgId}
                      setOrgId={setOrgId}
                    />
                  }
                />
                <Route
                  path={CREATE_OVEN_URL}
                  element={
                    currentRole != "manager" ? (
                      <CreateOven
                        mode="Create"
                        logoUrl={logoUrl}
                        dropdownData={dropdownData}
                        orgId={orgId}
                        setOrgId={setOrgId}
                      />
                    ) : (
                      <Navigate replace to="/" />
                    )
                  }
                />
                <Route
                  path={EDIT_OVEN_URL}
                  element={
                    currentRole != "manager" ? (
                      <CreateOven
                        mode="Edit"
                        logoUrl={logoUrl}
                        dropdownData={dropdownData}
                        orgId={orgId}
                        setOrgId={setOrgId}
                      />
                    ) : (
                      <Navigate replace to="/" />
                    )
                  }
                />
                <Route path="/locations" element={<Outlet />}>
                  <Route
                    path=""
                    element={
                      <Locations
                        logoUrl={logoUrl}
                        isDropdownLoading={isDropdownLoading}
                        dropdownData={dropdownData}
                        orgId={orgId}
                        setOrgId={setOrgId}
                      />
                    }
                  />

                  <Route path=":orgId/:storeId" element={<Outlet />}>
                    <Route path="" element={<StoreView />} />
                    {currentRole != "manager" ? (
                      <Route
                        path="edit"
                        element={
                          <EditLocation
                            refreshDropdown={refreshDropdown}
                            dropdownData={dropdownData}
                          />
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </Route>
                </Route>
                <Route path="/upload" element={<Outlet />}>
                  <Route
                    path="recipe"
                    element={
                      currentRole != "manager" ? (
                        <UploadRecipe
                          mode="Recipe"
                          logoUrl={logoUrl}
                          dropdownData={dropdownData}
                          orgId={orgId}
                          setOrgId={setOrgId}
                        />
                      ) : (
                        <Navigate replace to="/" />
                      )
                    }
                  />
                  <Route
                    path="config"
                    element={
                      currentRole != "manager" ? (
                        <UploadRecipe
                          mode="Config"
                          logoUrl={logoUrl}
                          dropdownData={dropdownData}
                          orgId={orgId}
                          setOrgId={setOrgId}
                        />
                      ) : (
                        <Navigate replace to="/" />
                      )
                    }
                  />
                  <Route
                    path="software"
                    element={
                      currentRole != "manager" ? (
                        <UploadRecipe
                          mode="Software"
                          logoUrl={logoUrl}
                          dropdownData={dropdownData}
                          orgId={orgId}
                          setOrgId={setOrgId}
                        />
                      ) : (
                        <Navigate replace to="/" />
                      )
                    }
                  />
                </Route>
                <Route
                  path={SETTINGS_URL}
                  element={
                    <>
                      <Settings />
                    </>
                  }
                />
                <Route
                  path={PROFILE_URL}
                  element={
                    <>
                      <Profile />
                    </>
                  }
                />
              </Routes>
            </Content>
          </Layout>
        </Router>
      </context.Provider>
    );
  }
};

export default AppLayout;
