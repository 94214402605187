import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import _ from "lodash";
import lottie from "lottie-web";

import { Col, Row, Spin } from "antd";
import { DownloadOutlined, FileAddOutlined } from "@ant-design/icons";
import uploading from "../img/uploading.json";
import doneuploading from "../img/doneuploading.json";
import erroruploading from "../img/erroruploading.json";

import { DropdownResponse } from "../api/responses";
import DropDowns, { selection } from "../components/common/dropDowns";
import SingleDropdown from "../components/common/singleDropDown";
import UploadButton, { FileListItem } from "../components/common/uploadButton";
import { getOvens, getRecentFiles, uploadRecipe } from "../api/portalData";
import MultiDropdown from "../components/common/multiDropDown";
import { dropDownItem, RecentFile, uploadModes } from "../config/types";
import { UploadFile } from "antd/lib/upload/interface";

import "./styles/ovens.less";
import BackButton from "../components/common/backButton";

interface Props {
  mode: uploadModes;
  logoUrl: string;
  dropdownData: DropdownResponse;
  orgId: number;
  setOrgId: React.Dispatch<React.SetStateAction<number>>;
}

const UploadRecipe: React.FC<Props> = (props) => {
  const location = useLocation();
  const locState = location.state as any;
  const navigate = useNavigate();

  const { dropdownData, orgId, setOrgId, mode } = props;
  const [dropdown, setDropdown] = useState<selection>({
    org: orgId,
    state: [],
    city: [],
    store: [],
  });
  const [selectedOvens, setSelectedOvens] = useState<string[]>([]);
  const [ovenList, setOvenList] = useState<any[] | null>(null);
  const [message, setMessage] = useState<JSX.Element | null>(null);
  const [fileList, setFileList] = useState<any[]>([]);
  const [recentFiles, setRecentFiles] = useState<RecentFile[]>([]);

  // const abortController = new AbortController();
  const onSelectionChange = (
    value: string[] | number,
    valueType: keyof selection | "oven"
  ) => {
    let selection = { ...dropdown };
    if (valueType == "org") {
      selection = { org: value as number, state: [], store: [], city: [] };
      setOrgId(value as number);
      // Getting Ovens and RecentFiles List
      setOvenList(null);
      getOvens(selection).then((resp) => {
        setOvenList(resp);
      });
      getRecentFiles(mode.toLowerCase(), selection.org).then((resp) => {
        setRecentFiles(resp);
      });
      setSelectedOvens([]);
    } else if (valueType === "state") {
      selection = {
        ...selection,
        store: [],
        city: [],
        state: value as string[],
      };
      setSelectedOvens([]);
    } else if (valueType === "city") {
      selection = {
        ...selection,
        store: [],
        city: value as string[],
      };
      setSelectedOvens([]);
    } else if (valueType === "store") {
      selection = {
        ...selection,
        store: value as string[],
      };
      setSelectedOvens([]);
    } else if (valueType == "oven") setSelectedOvens(value as string[]);

    setDropdown(selection);
  };
  // TODO - Change to useMemo or useCallback
  const filterOvens = () => {
    let ovens = _.cloneDeep(ovenList);
    if (dropdown.store.length > 0) {
      ovens = _.filter(ovens, (oven) => dropdown.store.includes(oven.storeId));
    } else if (dropdown.city.length > 0) {
      ovens = _.filter(ovens, (oven) =>
        dropdown.city.includes(oven.store.city + "," + oven.store.state)
      );
    } else if (dropdown.state.length > 0) {
      ovens = _.filter(ovens, (oven) =>
        dropdown.state.includes(oven.store.state)
      );
    }
    const options =
      ovens?.map((item) => _.pick(item, ["id", "name"]) as dropDownItem) ?? [];
    return options;
  };

  const uploadFilesCall = async (fileList: UploadFile[]) => {
    // Hide the form and shows the loading animation
    setMessage(<></>);

    lottie.loadAnimation({
      container: document.getElementById("loading") as HTMLElement,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: uploading,
    });
    try {
      const result = await uploadRecipe(
        fileList,
        dropdown.org,
        selectedOvens,
        mode.toLowerCase()
      );

      lottie.destroy();

      lottie.loadAnimation({
        container: document.getElementById("loading") as HTMLElement,
        renderer: "svg",
        loop: false,
        autoplay: true,
        animationData:
          result.success.length != 0 ? doneuploading : erroruploading,
      });

      setMessage(
        result.success.length != 0 ? (
          <span>
            {`Success : ${result.success.length} `}
            <br />
            {`Failure : ${result.failure.length} `}
          </span>
        ) : (
          <span className="error">{`Failed to Upload ${mode}`} </span>
        )
      );
    } catch (err) {
      lottie.destroy();

      lottie.loadAnimation({
        container: document.getElementById("loading") as HTMLElement,
        renderer: "svg",
        loop: false,
        autoplay: true,
        animationData: erroruploading,
      });
      setMessage(<span className="error">{`Error: Uploading ${mode}`} </span>);
    }
  };
  const backtoUpload = () => {
    lottie.destroy();
    setMessage(null);
  };

  const getRecentFilesCall = () => {
    getRecentFiles(mode.toLowerCase(), dropdown.org).then((resp) => {
      setRecentFiles(resp);
    });
  };

  const getPageTitle = (mode: uploadModes) => {
    switch (mode) {
      case "Config":
        return "Upload Configuration";
      case "Recipe":
        return "Upload Recipes";
      case "Software":
        return "Update Software";
    }
  };

  useEffect(() => {
    getOvens(dropdown).then((resp) => {
      setOvenList(resp);
      // On Page refresh, selectedOrg is cleared
      if (resp.length > 0) {
        setSelectedOvens(locState?.selectedOvens ?? []);
        setDropdown({ ...dropdown, store: locState?.store ?? [] });
      }
    });

    getRecentFilesCall();
  }, []);

  useEffect(() => {
    getRecentFilesCall();
    setMessage(null);
    setFileList([]);
  }, [mode]);

  return (
    <>
      <BackButton
        onBack={message == null ? () => navigate(-1) : () => backtoUpload()}
      />
      <div className="page-title">{getPageTitle(mode)}</div>
      <div>
        {message == null ? (
          <Spin spinning={ovenList == null} wrapperClassName="spin-wrapper">
            <Row className="upload-recipe-wrapper">
              <Col xs={24} md={12} lg={10}>
                <div style={{ fontSize: 24, textAlign: "center" }}>
                  Choose Location
                </div>
                <DropDowns
                  dropDownData={dropdownData}
                  dropdown={dropdown}
                  page="uploadRecipe"
                  onChange={onSelectionChange}
                />
                <Row
                  gutter={[16, 16]}
                  style={{ marginTop: 16, textAlign: "center" }}
                >
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <MultiDropdown
                      label="Oven"
                      valueType="oven"
                      selectAll
                      options={filterOvens()}
                      onChange={onSelectionChange}
                      value={selectedOvens}
                    />
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <SingleDropdown
                      label="Override"
                      value="override"
                      options={[
                        { id: "override", name: "Override" },
                        { id: "addmerge", name: "Add/Merge" },
                      ]}
                      onChange={console.log}
                      disabled
                    />
                  </Col>

                  <UploadButton
                    mode={mode}
                    fileList={fileList}
                    setFileList={setFileList}
                    disabled={selectedOvens.length < 1}
                    onUploadClicked={uploadFilesCall}
                  />
                </Row>
              </Col>
              <Col xs={24} md={12} lg={9} style={{ textAlign: "center" }}>
                <div style={{ fontSize: 24, marginBottom: 13 }}>
                  Recent files
                </div>
                {recentFiles.map((e, index: number) => (
                  <FileListItem
                    key={index}
                    actionIcon={
                      <span style={{ flexBasis: 50 }}>
                        <DownloadOutlined
                          onClick={() => window.open(e.url, "_blank")}
                        />
                        <FileAddOutlined
                          style={{ marginLeft: 10, cursor: "pointer" }}
                          onClick={() => setFileList([e])}
                        />
                      </span>
                    }
                    name={e.name}
                  />
                ))}
                {recentFiles.length < 1 ? "No Recent Files" : ""}
                <div className="show-mobile" style={{ height: 100 }}></div>
              </Col>
            </Row>
          </Spin>
        ) : (
          ""
        )}
        <div className={"loading-wrapper " + (message != null ? "active" : "")}>
          <div id="loading"></div>
          <div>{message}</div>
        </div>
      </div>
    </>
  );
};

export default UploadRecipe;
