import { Button, Form, Input, message, Upload } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import validator from "validator";

import { updateProfilePicture } from "../../api/portalData";
import { useWindowDimensions } from "../common/windowDimensions";
import PwdChangeModal from "./pwdChangeModal";

const ProfilePictureButtons: React.FC<any> = (props) => {
  const { imgUrl, onChange, onRemove } = props;
  return (
    <div className="grid-profile-picture-button">
      <img className="profile-picture-account-page" src={imgUrl} />
      <div className="margin-left-buttons">
        <div className="string-label">Profile picture</div>
        <Upload
          className="inline-buttons"
          beforeUpload={() => false}
          fileList={[]}
          onChange={onChange}
        >
          <Button className="inline-buttons" type="default">
            Change
          </Button>
        </Upload>
        <Button type="ghost" className="inline-buttons" onClick={onRemove}>
          Remove
        </Button>
      </div>
    </div>
  );
};

const LabelInput: React.FC<any> = (props) => {
  const {
    borderStyle,
    width,
    size,
    role,
    label,
    value,
    onInputChange,
    placeholderString,
    id,
  } = props;

  return (
    <div className={width < 576 ? "no-class-here" : "grid-label-inputs"}>
      <div className="input-labels">{label}</div>
      {role == "admin" || id == "name" || id == "email" || id == "phone" ? (
        <Input
          className={borderStyle}
          style={
            width > 576
              ? { marginTop: 30, height: 40, width: 400 }
              : { height: 40, width: size }
          }
          id={id}
          placeholder={value ? value : placeholderString}
          onChange={onInputChange}
        />
      ) : (
        <div className="input-values">{value ? value : "N/A"}</div>
      )}
    </div>
  );
};
const SettingsAccount: React.FC<any> = (props) => {
  const {
    profileData,
    updateTrigger,
    setUpdateTrigger,
    setProfileData,
    getProfileData,
  } = props;
  const windowDimensions = useWindowDimensions();
  const width = windowDimensions.width;
  const size = width - 60;

  const [modalOpen, SetModalOpen] = useState(false);
  const [phoneBorderStyle, setPhoneBorderStyle] = useState("1px solid #d9d9d9");

  // formData is used to keep track of updates to input fields. Will be used for posting new data on submition
  const [formData, setFormData] = useState(_.clone(profileData));

  async function onChangeProfilePicture(info: any) {
    try {
      if (info.file && info.file["type"].split("/")[0] === "image") {
        const result = await updateProfilePicture(info.file);
        getProfileData();
      } else {
        message.error("File is not an image file. Must be jpg, jpeg, or png");
      }
    } catch (err) {
      message.error("Failed to load picture - " + err);
    }
  }
  async function onRemoveProfilePicture() {
    try {
      const result = await updateProfilePicture("remove");
      if (result !== "There are no images to remove") {
        getProfileData();
      } else {
        message.success(result);
      }
    } catch (err) {
      message.error("Failed to load picture - " + err);
    }
  }
  const onInputChange = (e: any) => {
    e.target.value != ""
      ? setFormData({ ...formData, [e.target.id]: e.target.value })
      : setFormData({ ...formData, [e.target.id]: profileData[e.target.id] });
  };
  //  Change Profile Data on `save changes` clicked in parent
  useEffect(() => {
    if (validator.isMobilePhone(formData.phone) == true) {
      if (updateTrigger) {
        setProfileData(formData);
        setPhoneBorderStyle("good-phone-entry");
      }
    } else {
      message.error("Please enter a valid phone number");
      const phone = _.clone(profileData.phone);
      setFormData({ ...formData, phone: phone });
      setProfileData({ ...formData, phone: phone });
      setPhoneBorderStyle("bad-phone-entry");
      setUpdateTrigger(false);
    }
  }, [updateTrigger]);

  useEffect(() => {
    setFormData(_.clone(profileData));
  }, [profileData]);

  return (
    <div className="main-div">
      <ProfilePictureButtons
        imgUrl={profileData.imgUrl || undefined}
        onChange={onChangeProfilePicture}
        onRemove={onRemoveProfilePicture}
      />

      <LabelInput
        width={width}
        size={size}
        role={profileData.role}
        label="Name"
        value={formData.name}
        onInputChange={onInputChange}
        placeholderString="Enter a name"
        id="name"
      />
      <LabelInput
        width={width}
        size={size}
        role={profileData.role}
        label="Email"
        value={formData.email}
        onInputChange={onInputChange}
        placeholderString="Enter an email"
        id="email"
      />
      <div className={width < 576 ? "no-class-here" : "grid-label-inputs"}>
        <div className="input-labels">Password</div>
        <div>
          <Input.Password
            style={
              width > 576
                ? { marginTop: 30, height: 40, width: 400 }
                : { height: 40, width: size }
            }
            disabled={true}
            defaultValue={"something"}
            iconRender={() => false}
          />
          <Button
            type="link"
            className="change-password"
            onClick={() => SetModalOpen(true)}
          >
            Change Password
          </Button>
        </div>
      </div>

      <LabelInput
        borderStyle={phoneBorderStyle}
        width={width}
        size={size}
        role={profileData.role}
        label="Phone Number"
        value={formData.phone}
        onInputChange={onInputChange}
        placeholderString="Enter a phone number"
        id="phone"
      />

      <div className={width < 576 ? "no-class-here" : "grid-label-inputs"}>
        <div className="input-labels">Account Role</div>
        <div className="input-values">
          {profileData.role ? profileData.role : "N/A"}
        </div>
      </div>

      <div className={width < 576 ? "no-class-here" : "grid-label-inputs"}>
        <div className="input-labels">Admin Contact</div>
        <div className="input-values">
          {profileData.adminContact ? profileData.adminContact : "N/A"}
        </div>
      </div>

      <div className={width < 576 ? "no-class-here" : "grid-label-inputs"}>
        <div className="input-labels">Company</div>
        <div className="input-values">
          {profileData.company ? profileData.company : "N/A"}
        </div>
      </div>

      {modalOpen ? <PwdChangeModal onCancel={() => SetModalOpen(false)} /> : ""}
    </div>
  );
};
export default SettingsAccount;
