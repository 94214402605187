import { Select } from "antd";
import { dropDownItem } from "../../config/types";

export type dropdownType = "state" | "city" | "store";

const { Option } = Select;

interface iMultiSelectProps {
  label: string;
  value: string[];
  // TODO - Cleanup valueType (type-dropdownType)
  valueType: dropdownType | "oven";
  options: dropDownItem[];
  disabled?: boolean;
  selectAll?: boolean;
  onChange(selectedValues: string[], valueType: string): void;
  isLoading?: boolean;
}

const MultiDropdown: React.FC<iMultiSelectProps> = (props) => {
  const { label, valueType, value, disabled, options, selectAll, isLoading } =
    props;

  const optionTags: Array<JSX.Element> = [];
  if (selectAll && options.length > 1)
    optionTags.push(
      <Option key="all" value="all" className="dropdown-text">
        {"All"}
      </Option>
    );
  for (let i = 0; i < options.length; i++) {
    optionTags.push(
      <Option key={i} value={options[i].id} className="dropdown-text">
        {options[i].name}
      </Option>
    );
  }
  const multiSelectChange = (e: string[], valueType: string) => {
    if (selectAll && e.includes("all")) {
      const selected = options.map((e) => e.id);
      props.onChange(selected, valueType);
    } else props.onChange(e, valueType);
  };

  return (
    <>
      <div className="dropdown-title">{label}</div>
      <Select
        loading={isLoading}
        value={value}
        mode="multiple"
        allowClear
        placeholder="Select options"
        style={{
          width: "100%",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          textAlign: "left",
        }}
        maxTagCount={valueType == "state" ? 2 : 1}
        onChange={(e) => multiSelectChange(e, valueType)}
        disabled={disabled}
        optionFilterProp="children"
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        onFocus={(event) => event.target.scrollIntoView()}
      >
        {optionTags}
      </Select>
    </>
  );
};

export default MultiDropdown;
