import "./App.less";
import AppLayout from "./pages/appLayout";
import {
  AuthenticatedTemplate,
  useMsalAuthentication,
} from "@azure/msal-react";

// MSAL imports
import { InteractionType } from "@azure/msal-browser";

function App() {
  const { login, error } = useMsalAuthentication(InteractionType.Redirect);

  if (error) {
    login(InteractionType.Redirect);
  }

  return (
    <AuthenticatedTemplate>
      <AppLayout />
    </AuthenticatedTemplate>
  );
}

export default App;
