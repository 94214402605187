import { Dropdown, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { PROFILE_URL, SETTINGS_URL } from "../../config/constants";

import UserIcon from "../../img/icon-outlined-application-user.svg";
import "./styles.less";

interface ProfileMenuProps {
  logout: () => void;
}

const ProfileMenu: React.FC<ProfileMenuProps> = (props) => {
  const navigate = useNavigate();
  return (
    <Dropdown
      overlay={
        <Menu className="profile-menu">
          <Menu.Item key="profile" onClick={() => navigate(PROFILE_URL)}>
            Profile
          </Menu.Item>
          <Menu.Item key="settings" onClick={() => navigate(SETTINGS_URL)}>
            Settings
          </Menu.Item>
          <Menu.Divider style={{ height: 2, borderRadius: 1 }}></Menu.Divider>
          <Menu.Item key="logout" onClick={props.logout}>
            Logout
          </Menu.Item>
        </Menu>
      }
      trigger={["click"]}
      placement="bottomRight"
      overlayStyle={{ minWidth: 200 }}
    >
      <img src={UserIcon} />
    </Dropdown>
  );
};

export default ProfileMenu;
