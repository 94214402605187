import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Row, Spin } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { updateLocation } from "../api/portalData";

import BackButton from "../components/common/backButton";
import ConfirmButton from "../components/common/confirmButton";
import SingleDropdown from "../components/common/singleDropDown";
import { allStates } from "../config/states";

const EditLocation: React.FC<any> = (props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const locState = location.state as any;
  const { orgId, storeId } = useParams<{ orgId: string; storeId: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [currentState, setCurrentState] = useState<any>(
    locState.storeData[0].state
  );

  const filteredOrg = props.dropdownData.orgs.filter(
    (value: { id: string | undefined }) => value.id == orgId
  );
  const companyName = filteredOrg.length > 0 ? filteredOrg[0].name : null;

  if (locState == null || undefined) {
    navigate(-1);
  }

  useEffect(() => {
    form.setFieldsValue({
      storeId: storeId,
      orgName: companyName,
      remove: false,
    });
  }, [companyName]);

  const handleChange = (value: string | number | undefined) => {
    setCurrentState(value);
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const result = await updateLocation(values);
      message.success(result);
      props.refreshDropdown();
      navigate(-1);
    } catch (err) {
      message.error("Failed to update location data" + err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Spin spinning={loading}>
        <BackButton onBack={() => navigate(-1)} />
        <div className="page-title">Edit Location</div>

        <Row className="form-wrapper">
          <Form
            name="editLocation"
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            className="main-form"
            form={form}
          >
            <div style={{ marginTop: 20 }} />
            <div className="dropdown-title">Company</div>
            <Form.Item hidden={true} name="remove" rules={[{ required: true }]}>
              <></>
            </Form.Item>
            <Form.Item
              hidden={true}
              name="storeId"
              rules={[{ required: true }]}
            >
              <></>
            </Form.Item>
            <Form.Item name="orgName" rules={[{ required: true }]}>
              <div className="dropdown-text">{companyName}</div>
            </Form.Item>
            <div style={{ marginBottom: 10 }} />
            <div className="dropdown-title">Street Address</div>
            <Form.Item
              initialValue={
                locState.storeData[0].address1 +
                  " " +
                  locState.storeData[0].address2 ?? ""
              }
              name="address"
              rules={[{ required: true, message: "" }]}
            >
              <Input />
            </Form.Item>
            <div className="dropdown-title">State</div>
            <Form.Item
              initialValue={currentState ?? ""}
              name="state"
              rules={[{ required: true, message: "" }]}
            >
              <SingleDropdown
                allowClear
                value={currentState ?? ""}
                valueType="state"
                options={allStates.map((element) => ({
                  ...element,
                  id: element.name,
                }))}
                onChange={handleChange}
              />
            </Form.Item>

            <div style={{ marginBottom: 12 }} />

            <div className="dropdown-title">City</div>
            <Form.Item
              initialValue={locState.storeData[0].city ?? ""}
              name="city"
              rules={[{ required: true, message: "" }]}
            >
              <Input />
            </Form.Item>
            <div className="dropdown-title">Store ID</div>
            <Form.Item
              initialValue={locState.storeData[0].name ?? ""}
              name="storeIdName"
              rules={[{ required: true, message: "" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <div className="btn-grp nowrap">
                <Button type="primary" htmlType="submit">
                  Save Changes
                </Button>
                <ConfirmButton
                  buttonText={"Delete Location"}
                  onOk={() => onFinish({ storeId: storeId, remove: true })}
                  warningText="Are you sure you want to delete this location?"
                  secondaryText=" You cannot undo this action."
                  okText="Delete"
                />
              </div>
            </Form.Item>
          </Form>
        </Row>
      </Spin>
    </>
  );
};
export default EditLocation;
