import { Row, Col, Card, Button } from "antd";
import React from "react";
import UpdateIcon from "../../img/alert-circle-blue.svg";
import FixIcon from "../../img/alert-circle-red.svg";
import { store } from "../../config/types";

export const getAddressString = (data: store): string => {
  const addressKeys = ["address1", "address2", "city", "state", "zipcode"];
  let address = "";
  addressKeys.forEach((i) => {
    if (data[i as keyof store] != "") {
      address += data[i as keyof store] + " , ";
    }
  });
  return address.slice(0, -3);
};
interface storeCardProps {
  storeData: store;
  onDetailsClicked(storeData: store): void;
}

const StoreCard: React.FC<storeCardProps> = ({
  storeData,
  onDetailsClicked,
}) => {
  return (
    <Col span={24}>
      <Card
        style={{
          border: "1px solid #707070",
          borderRadius: 10,
          fontSize: 18,
          maxWidth: 700,
          margin: "0 auto",
        }}
      >
        <Row gutter={[8, 8]}>
          <Col xs={24} lg={16}>
            <h1>
              <b>{storeData.name}</b>
            </h1>
            <span>
              <b>{`Address : `}</b>
            </span>
            <span>{getAddressString(storeData)}</span>
            <br />
            <span>
              <b>{`Number of Ovens : `}</b>
            </span>
            <span>{storeData.ovens ? storeData.ovens : 0}</span>
            <br />
            <span>
              <b>{`Errors : `}</b>
            </span>
            <span>{storeData.errors ? storeData.errors : 0}</span>
            <br />
          </Col>

          <Col xs={24} lg={8}>
            <Row gutter={[8, 4]}>
              {storeData.errors > 0 ? (
                <Col xs={16} lg={24}>
                  <img src={FixIcon} style={{ height: "24px" }} />
                  {` Maintenance Required`}
                </Col>
              ) : (
                ""
              )}
              {storeData.updates > 0 ? (
                <Col xs={8} lg={24}>
                  <img src={UpdateIcon} style={{ height: "24px" }} />
                  {` Updates Available`}
                </Col>
              ) : (
                ""
              )}
            </Row>
          </Col>
        </Row>
        <Row style={{ float: "right", marginTop: 10 }}>
          <Button type="primary" onClick={() => onDetailsClicked(storeData)}>
            Details
          </Button>
          {/* <Button type="default" >View Ovens</Button> */}
        </Row>
      </Card>
    </Col>
  );
};

export default StoreCard;
