import { Row, Col, Spin, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StoreCard from "../components/store/storeCard";

import DropDowns, { selection } from "../components/common/dropDowns";
import PageTitle from "../components/common/pageTitle";
import { getStores } from "../api/portalData";
import { store } from "../config/types";
import { DropdownResponse } from "../api/responses";

interface Props {
  logoUrl: string;
  dropdownData: DropdownResponse;
  orgId: number;
  setOrgId: React.Dispatch<React.SetStateAction<number>>;
  isDropdownLoading: boolean;
}

const Locations: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { logoUrl, dropdownData, orgId, setOrgId, isDropdownLoading } = props;

  const [storesData, setStoresData] = useState<null | any>(null);

  const [dropdown, setDropdown] = useState<selection>({
    org: orgId,
    state: [],
    city: [],
    store: [],
  });

  const getStoresData = (selection: selection, abortController: any) => {
    // TODO - Restrict Initial API call for admin with Org=0
    setStoresData(null);
    getStores(selection, abortController.signal)
      .then((resp) => {
        setStoresData(resp);
      })
      .catch((error) => {
        if (error.message != "AbortError") {
          setStoresData([]);
          // <-- this is being called after component unmounts!
          message.error("Stores API Error , " + error.message);
        }
      });
  };

  // Get Stores Data when drop down values change
  useEffect(() => {
    const abortController = new AbortController();
    getStoresData(dropdown, abortController);

    return () => {
      abortController.abort();
    };
  }, [dropdown]);

  const onSelectionChange = (
    value: string[] | number,
    valueType: keyof selection
  ) => {
    let selection = { ...dropdown };
    if (valueType == "org") {
      // Clearing other selection on Org Change
      selection = { org: value as number, state: [], store: [], city: [] };
      setOrgId(value as number);
    } else if (valueType == "state") {
      selection = {
        ...selection,
        city: [],
        state: value as string[],
      };
    } else selection[valueType] = value as string[];

    setDropdown(selection);
  };

  const onDetailsClicked = (store: store) => {
    navigate(`${store.orgId}/${store.id}`);
  };
  return (
    <Col style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <PageTitle title="Locations" logoUrl={logoUrl} />

      <DropDowns
        dropDownData={dropdownData}
        isDropdownLoading={isDropdownLoading}
        dropdown={dropdown}
        page="locations"
        onChange={onSelectionChange}
      />

      <Row
        style={{
          margin: "20px auto",
          width: "100%",
          rowGap: 15,
          overflowY: "auto",
          flexGrow: 1,
        }}
      >
        {storesData === null ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              overflow: "hidden",
              display: "flex",
            }}
          >
            <Spin
              spinning={true}
              style={{
                position: "relative",
                margin: "auto",
                height: "auto",
                padding: 10,
              }}
            ></Spin>
          </div>
        ) : (
          <>
            {storesData.map((e: store) => (
              <StoreCard
                key={e.id}
                storeData={e as store}
                onDetailsClicked={(e) => onDetailsClicked(e)}
              />
            ))}
            <div style={{ width: "100%", height: 50 }}></div>
          </>
        )}
      </Row>
    </Col>
  );
};

export default Locations;
