import { Button } from "antd";
import { useWindowDimensions } from "./windowDimensions";

import "./styles.less";

interface Props {
  title: string;
  buttonTitle: string;
  buttonAction: () => void;
}
const PSWrapper: React.FC<Props> = ({
  title,
  buttonAction,
  buttonTitle,
  children,
}) => {
  const windowDimensions = useWindowDimensions();

  return (
    <div className="settings-menu-main-div">
      <div className="grid-container">
        <div className="settings-string">{title}</div>
        {windowDimensions.width > 576 ? (
          <Button
            className="save-changes-button"
            type="primary"
            onClick={buttonAction}
          >
            {buttonTitle}
          </Button>
        ) : (
          <></>
        )}
      </div>
      {children}
      {windowDimensions.width > 576 ? (
        <></>
      ) : (
        <div className="btn-grp">
          <Button
            className="save-changes-button-phone-view"
            type="primary"
            onClick={buttonAction}
          >
            {buttonTitle}
          </Button>
        </div>
      )}
    </div>
  );
};

export default PSWrapper;
