import { Configuration } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "d30cf92f-31cc-48b9-8258-4ee00b58c748",
        authority: "https://oventioniot.b2clogin.com/tfp/oventioniot.onmicrosoft.com/B2C_1_ovention_signin",
        knownAuthorities: ["https://oventioniot.b2clogin.com/tfp/oventioniot.onmicrosoft.com/B2C_1_ovention_signin"],
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    }, 
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      }
};