import { Button, Form, Input, message, Modal, Spin } from "antd";
import { useState } from "react";
import { editUser } from "../../api/portalData";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    span: 24,
  },
};

const PwdChangeModal = ({ onCancel }: { onCancel: () => void }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const onSubmit = async (values: { password: string }) => {
    const password = values["password"];
    setLoading(true);
    try {
      const resp = await editUser({ password });
      message.success("Successfully updated password");
      onCancel();
    } catch (err) {
      setLoading(false);
      message.error(err + ""); //parsing unknown to string
    }
  };
  return (
    <Modal
      title={
        <span style={{ fontSize: 24, fontWeight: "bold" }}>
          Change Password
        </span>
      }
      onCancel={onCancel}
      visible={true}
      footer={null}
    >
      <Spin size="large" spinning={loading}>
        <Form
          {...formItemLayout}
          labelAlign="left"
          form={form}
          onFinish={onSubmit}
        >
          <Form.Item
            name="password"
            label={
              <span style={{ fontSize: 16, fontWeight: "bold" }}>Password</span>
            }
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              () => ({
                validator(_, value) {
                  const charClasses =
                    Number(/[A-Z]+/g.test(value)) + //UpperCase Check
                    Number(/[a-z]+/g.test(value)) + //LowerCase Check
                    Number(/\d+/g.test(value)) + //Number Check
                    Number(/[^a-zA-Z0-9]+/g.test(value)); // Symbol Check

                  //value can be undefined, Skip for undefined error as it is caught in require true
                  if (!value || (value.length >= 8 && charClasses >= 3)) {
                    return Promise.resolve();
                  } else if (value.length < 8)
                    return Promise.reject(
                      new Error("Should contain atleast 8 characters")
                    );
                  else
                    return Promise.reject(
                      new Error(
                        "Should contain 3 of 4 character classes - uppercase, lowercase, number, symbol"
                      )
                    );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label={
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Confirm Password
              </span>
            }
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="default" onClick={onCancel}>
                Close
              </Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default PwdChangeModal;
