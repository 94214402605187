import { Col, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { context } from "../../pages/appLayout";
import * as _ from "lodash";
import { DropdownResponse } from "../../api/responses";
import { dropDownItem } from "../../config/types";
import SingleDropdown from "./singleDropDown";
import MultiDropdown, { dropdownType } from "./multiDropDown";

interface DropDownProps {
  dropdown: selection;
  dropDownData: DropdownResponse;
  page: "ovens" | "locations" | "uploadRecipe";
  onChange: (value: number | string[], valueType: keyof selection) => void;
  isDropdownLoading?: boolean;
}

export type selection = {
  org: number;
  state: string[];
  city: string[];
  store: string[];
};

export interface IDropdownOptions {
  orgs: dropDownItem[];
  states: dropDownItem[];
  cities: dropDownItem[];
  stores: dropDownItem[];
}

export const parseFilterResponse = (
  items: DropdownResponse,
  selection: selection
): IDropdownOptions => {
  // Orgs
  const parsedOrgs: dropDownItem[] = [];
  items.orgs.map((item: any) => {
    parsedOrgs.push({ id: item.id, name: item.name });
  });
  let states = items.states;
  // States
  const parsedStates: dropDownItem[] = Object.keys(states).map((e: string) => ({
    id: e,
    name: e,
  }));
  let stores = items.stores;
  if (selection.state.length > 0) {
    states = _.pick(states, selection.state);
    const statesList: string[] = selection.state;
    // Filtering Stores
    stores = _.filter(stores, (store) => statesList.includes(store.state));
  }
  // Cities
  const parsedCities: dropDownItem[] = Object.values(states)
    .flat()
    .map((e: string) => ({ id: e, name: e }));

  if (selection.city.length > 0) {
    const cities: string[] = selection.city;
    // Filtering Stores
    stores = _.filter(stores, (store) =>
      cities.includes(store.city + "," + store.state)
    );
  }
  // Stores
  const parsedStores: dropDownItem[] = [];
  stores.map((store: any) => {
    parsedStores.push({ id: store.id, name: store.name });
  });

  return {
    orgs: parsedOrgs,
    states: parsedStates,
    cities: parsedCities,
    stores: parsedStores,
  };
};

const DropDowns: React.FC<DropDownProps> = (props) => {
  const [currentOptions, setCurrentOptions] = useState<IDropdownOptions>({
    orgs: [],
    states: [],
    cities: [],
    stores: [],
  });

  const role = useContext(context);

  const getCurrentData = (
    items: DropdownResponse,
    selection: selection
  ): void => {
    const data = parseFilterResponse(items, selection);
    setCurrentOptions(data);
  };

  // On Mount
  useEffect(() => {
    getCurrentData(props.dropDownData, props.dropdown);
  }, []);

  // On Props Change
  useEffect(() => {
    getCurrentData(props.dropDownData, props.dropdown);
  }, [props.dropDownData, props.dropdown]);

  const handleOrgChange = (value: number) => {
    props.onChange(value, "org");
  };

  const handleMultiChange = (value: string[], valueType: dropdownType) => {
    const selected = _.cloneDeep(props.dropdown);
    selected[valueType] = value;
    parseFilterResponse(props.dropDownData, selected);

    props.onChange(value, valueType);
  };

  return (
    <Row
      gutter={[16, 16]}
      style={{
        marginTop: "13px",
      }}
    >
      {role === "admin" ? (
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={props.page === "uploadRecipe" ? 12 : 5}
        >
          <SingleDropdown
            label="Company"
            value={props.dropdown.org}
            options={currentOptions.orgs}
            onChange={handleOrgChange}
          />
        </Col>
      ) : (
        <></>
      )}
      <Col xs={12} sm={12} md={12} lg={props.page === "uploadRecipe" ? 12 : 5}>
        <MultiDropdown
          isLoading={props.isDropdownLoading}
          disabled={props.isDropdownLoading}
          label="State"
          valueType="state"
          options={currentOptions.states}
          // disabled={props.dropdown.city.length > 0}
          onChange={handleMultiChange}
          value={props.dropdown.state}
        />
      </Col>
      <Col xs={12} sm={12} md={12} lg={props.page === "uploadRecipe" ? 12 : 5}>
        <MultiDropdown
          isLoading={props.isDropdownLoading}
          disabled={props.isDropdownLoading}
          label="City"
          value={props.dropdown.city}
          valueType="city"
          options={currentOptions.cities}
          onChange={handleMultiChange}
        />
      </Col>{" "}
      {props.page != "locations" ? (
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={props.page === "uploadRecipe" ? (role === "admin" ? 12 : 24) : 5}
        >
          <MultiDropdown
            isLoading={props.isDropdownLoading}
            disabled={props.isDropdownLoading}
            label="Store"
            value={props.dropdown.store}
            valueType="store"
            options={currentOptions.stores}
            onChange={handleMultiChange}
          />
        </Col>
      ) : (
        ""
      )}
    </Row>
  );
};

export default DropDowns;
