import _ from "lodash";
import { useContext } from "react";
import { Modal, Row, Col, Button, message } from "antd";
import { Link } from "react-router-dom";

import {
  UPLOAD_CONFIG_URL,
  UPLOAD_RECIPE_URL,
  UPLOAD_SOFTWARE_URL,
} from "../../config/constants";

import closeIcon from "../../img/close-outlined-circle.svg";

import "./styles.less";
import { context } from "../../pages/appLayout";
import { oven } from "../../config/types";
import BackButton from "../common/backButton";
import { useWindowDimensions } from "../common/windowDimensions";
import { createOven, getOvenConfig } from "../../api/portalData";

const Properties = [
  ["Oven name", ["name"]],
  ["Serial number", ["serialNo"]],
  ["Model", ["model"]],
  ["Location", []],
  ["Software version", ["key"]],
  ["Configuration version", ["key"]],
  ["Recipes version", ["data", "menuCurrentVersion"]],
];

const Data = [
  ["Status", ["ovenStatus"]],
  ["Error", ["errors"]],
  ["Cooks", ["cooks"]],
  ["Hours of Operation", ["hoursOperational"]],
  ["Voltage", ["key"]],
  ["Current", ["key"]],
  ["Temperature", ["key"]],
];

interface ovenPopupProps {
  ovenData: oven;
  onClose: () => void;
}

const OvenPopup: React.FC<ovenPopupProps> = (props) => {
  const role = useContext(context);
  const windowDimensions = useWindowDimensions();

  const DetailsButton = (
    <Button type="default">
      <Link
        to={`/locations/${props.ovenData.store.orgId}/${props.ovenData.store.id}`}
      >
        Store Details
      </Link>
    </Button>
  );

  const uploadPageState = {
    selectedOvens: [props.ovenData.id],
    store: [props.ovenData.store.id],
  };

  const downloadConfig = async () => {
    const result = await getOvenConfig(
      props.ovenData.deviceId,
      props.ovenData.model
    );
    message.success("Config file will be downloded automatically.");
    result.forEach((e) => {
      const downloadEle = document.createElement("a");
      downloadEle.setAttribute("href", e[1]);
      downloadEle.setAttribute("download", `config_${e[0]}.json`);
      downloadEle.click();
      downloadEle.remove();
    });
  };

  return (
    <Modal
      visible={true}
      footer={null}
      onCancel={props.onClose}
      closeIcon={
        <img src={closeIcon} className="oven-popup-close hide-mobile" />
      }
      maskClosable={false}
      className="oven-popup"
      // z-Index set for mobile view (less than the header's z-Index)
      zIndex={50}
    >
      {windowDimensions.width < 576 ? (
        <>
          <BackButton onBack={props.onClose} />
          <div className="page-title">Oven Properties</div>
          <br />
        </>
      ) : (
        <></>
      )}

      <Row gutter={[32, 16]}>
        {/* Back button and Title for Mobile View */}

        <Col xs={24} lg={12} className="oven-params op-data-border">
          {" "}
          <div className="op-col-title">Properties</div>
          {Properties.map((e, index) => (
            <div key={index}>
              <span style={{ float: "left" }}>{`${e[0]} :`}</span>
              <span style={{ float: "right" }}>
                <b>
                  {e[0] === "Location"
                    ? props.ovenData.store.city +
                      " , " +
                      props.ovenData.store.state
                    : _.get(props.ovenData, e[1], "N/A")}
                </b>
              </span>
            </div>
          ))}
        </Col>
        <Col xs={24} lg={12} className="oven-params">
          {" "}
          <div className="op-col-title">Data</div>
          {Data.map((e, index) => (
            <div key={index}>
              <span style={{ float: "left" }}>{`${e[0]} :`}</span>
              <span style={{ float: "right" }}>
                <b>{_.get(props.ovenData, e[1], "N/A")}</b>
              </span>
            </div>
          ))}
        </Col>
        <Col xs={24} lg={24} className="btn-grp">
          {role != "manager" ? (
            <>
              <Button type="default">
                <Link to={UPLOAD_RECIPE_URL} state={uploadPageState}>
                  Add Recipe
                </Link>
              </Button>
              <Button type="default">
                <Link to={UPLOAD_SOFTWARE_URL} state={uploadPageState}>
                  Update Software
                </Link>
              </Button>
              <Button type="default">
                <Link to={UPLOAD_CONFIG_URL} state={uploadPageState}>
                  Update configuration
                </Link>
              </Button>
              {role == "admin" && (
                <Button type="default" onClick={() => downloadConfig()}>
                  Download Config
                </Button>
              )}

              {DetailsButton}
              <Button type="default">
                <Link to={`/oven/${props.ovenData.id}/edit`}>Edit Oven</Link>
              </Button>
            </>
          ) : (
            DetailsButton
          )}
        </Col>
      </Row>
    </Modal>
  );
};
export default OvenPopup;
