import { Table } from "antd";
import "./styles.less";
import { errorData } from "../../config/types";

const columns = [
  {
    title: "Error number",
    dataIndex: "errorCode",
    key: "errorCode",
    width: 40,
  },
  {
    title: "Error",
    dataIndex: "error",
    key: "error",
  },
  {
    title: "Time/Date",
    dataIndex: "time",
    key: "time",
    render: (data: string) =>
      new Date(data + "Z").toLocaleString([], {
        year: "2-digit",
        month: "2-digit",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      }),
  },
];

const ErrorTable = ({ data }: { data: errorData[] }) => {
  return (
    <Table
      className="errorTable"
      dataSource={data}
      pagination={false}
      columns={columns}
    />
  );
};

export default ErrorTable;
