import { Spin } from "antd";

const Loader: React.FC = () => {
  return (
    <Spin
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
      spinning
    />
  );
};

export default Loader;
