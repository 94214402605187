import { Checkbox, Select } from "antd";
import React, { useContext, useState } from "react";

import { context } from "../../pages/appLayout";
import { useWindowDimensions } from "../common/windowDimensions";
import "./styles.less";

const SettingsNotifications: React.FC<any> = (props) => {
  const { profileData } = props;
  const role = useContext(context);
  const { Option } = Select;
  const windowDimensions = useWindowDimensions();
  const notificationCheckboxes =
    role === "manager"
      ? ["Errors"]
      : [
          "Errors",
          "Software available",
          "Configuration available",
          "New recipes available",
          "New ovens created",
        ];
  const createKeyValuePairs = (notificationCheckboxes: string[]) => {
    const checkboxTemplate: any = {};
    for (let i = 0; i < notificationCheckboxes.length; i++) {
      checkboxTemplate[notificationCheckboxes[i]] = false;
    }
    return checkboxTemplate;
  };
  const [availableCheckboxes, setAvailableCheckboxes] = useState(
    createKeyValuePairs(notificationCheckboxes)
  );
  // get this from the DB and change according in the change functions
  const [allNotificationState, setAllNotificationState] = useState(null);

  function onChange(checkedValues: any, key: any) {
    availableCheckboxes[key] = checkedValues.target.checked;
  }

  const PhoneEmailDivs = () => {
    if (windowDimensions.width > 576) {
      return (
        <div className="grid-container-email-phone-labels">
          <div className="email-block">
            <div className="string-label">Email</div>
            <div className="string-value">
              {profileData.email ? profileData.email : "N/A"}
            </div>
          </div>
          <div className="phone-block">
            <div className="string-label">Phone Number</div>
            <div className="string-value">
              {profileData.phone ? profileData.phone : "N/A"}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="string-label">Email and Phone Number</div>
          <div className="string-value">
            {profileData.email ? profileData.email : "N/A"}
          </div>
          <div className="string-value">
            {profileData.phone ? profileData.phone : "N/A"}
          </div>
          <div style={{ marginBottom: "20px" }}></div>
        </div>
      );
    }
  };

  const NotificationsTable: any = (notifications: any) => {
    const elementArray = [];
    for (let i = 0; i < notifications.labels.length; i++) {
      elementArray.push(
        <React.Fragment key={i}>
          <div
            className={
              i % 2 == 0 ? "notifications-label-grey" : "notifications-label"
            }
          >
            {notifications.labels[i]}:
          </div>
          <Checkbox
            className={
              i % 2 == 0
                ? "notifications-checkbox-grey"
                : "notifications-checkbox"
            }
            defaultChecked={false}
            onChange={(e) => onChange(e, notifications.labels[i])}
          />
        </React.Fragment>
      );
    }
    return elementArray;
  };

  function handleFrequencyChange(value: any) {
    console.log(value);
  }
  const size = windowDimensions.width - 90;
  const gridColumns = "[label] " + size + "px [checkbox] 50px";
  return (
    <div className="main-div">
      <PhoneEmailDivs />
      <div className="string-label">Select Notifications To Receive</div>
      {windowDimensions.width > 576 ? (
        <div className="grid-notifications-table">
          <NotificationsTable labels={notificationCheckboxes} />
        </div>
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: gridColumns,
          }}
        >
          <NotificationsTable labels={notificationCheckboxes} />
        </div>
      )}

      <div className="frequency-label">Notification Frequency</div>
      <Select
        defaultValue="Once a week"
        className="frequency-dropdown"
        onChange={handleFrequencyChange}
      >
        <Option value="daily">Once a day</Option>
        <Option value="weekly">Once a week</Option>
        <Option value="monthly">Once a month</Option>
      </Select>
    </div>
  );
};
export default SettingsNotifications;
