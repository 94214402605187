import { Row, Col, Select, message } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import OvenDetails from "../components/oven/ovenDetails";
import OvenCard from "../components/oven/ovenCard";
import { getOvens } from "../api/portalData";
import { store } from "../config/types";
import { selection } from "../components/common/dropDowns";
import Loader from "../components/utils/Loader";
import BackButton from "../components/common/backButton";

import "./styles/storeView.less";

const { Option } = Select;

const StoreView: React.FC = () => {
  const { orgId, storeId } = useParams<{ orgId: string; storeId: string }>();

  const [ovensList, setOvenList] = useState<Array<any> | null>(null);
  const [selection, setSelection] = useState({ store: {}, oven: {} as any });

  useEffect(() => {
    const params: selection = {
      org: orgId ? parseInt(orgId) : 0,
      state: [],
      city: [],
      store: [storeId ? storeId : ""],
    };
    getOvens(params)
      .then((resp) => {
        if (resp.length == 0) {
          message.warning("No ovens to diplay in this store");
        } else {
          setSelection({
            oven: resp[0],
            store: { ...resp[0].store, ovens: resp.length },
          });
        }
        setOvenList(resp);
      })
      .catch((err) => {
        setOvenList([]);
        message.error("Error getting store details");
      });
  }, []);

  //onChange for OvenDropDown - Mobile View
  const onOvenSelectionChange = (id: string) => {
    const oven = ovensList?.find((e) => e.id === id);
    setSelection({ ...selection, oven });
  };

  // Dropdown to select Oven in Mobile View
  const OvenDropDown = (props: { onChange(id: string): void }) => (
    <div className={"show-mobile"} style={{ fontSize: 18 }}>
      {` Oven : `}
      <Select
        onChange={props.onChange}
        value={selection.oven.id}
        className="ovens-dropdown"
      >
        {ovensList?.map((e) => (
          <Option key={e.id} value={e.id}>
            {e.name}
          </Option>
        ))}
      </Select>
    </div>
  );

  return (
    <Col
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {ovensList != null ? (
        <Row
          style={{
            flexGrow: 1,
            marginTop: 20,
            overflowY: "hidden",
          }}
        >
          <Col
            xs={0}
            md={8}
            lg={6}
            style={{
              height: "100%",
            }}
          >
            <BackButton />
            <Row
              gutter={[0, 18]}
              style={{
                marginTop: 60,
                height: "calc( 100% - 60px)",
                overflowY: "auto",
                paddingRight: 20,
              }}
            >
              {ovensList?.map((e) => (
                <OvenCard
                  key={e.id}
                  ovenData={e}
                  active={e.id == selection.oven.id}
                  showWarning={true}
                  onClick={() => setSelection({ ...selection, oven: e })}
                />
              ))}

              <div style={{ height: 50 }}></div>
            </Row>
          </Col>
          {ovensList.length > 0 ? (
            <Col xs={24} md={16} lg={18} className="ovenDetails-col">
              <OvenDetails
                storeData={selection.store as store}
                ovenData={selection.oven}
              >
                <OvenDropDown onChange={onOvenSelectionChange}></OvenDropDown>
              </OvenDetails>
            </Col>
          ) : (
            ""
          )}
        </Row>
      ) : (
        <Loader />
      )}
    </Col>
  );
};

export default StoreView;
