import { Upload, message, Button, Col } from "antd";
import { InboxOutlined, DeleteOutlined } from "@ant-design/icons";
import React, { ReactElement } from "react";

import { UploadFile } from "antd/lib/upload/interface";

import _ from "lodash";

import { uploadModes } from "../../config/types";

import recipeIcon from "../../img/RecipeIconNew.svg";

import "./styles.less";

const { Dragger } = Upload;

interface UploadButtonProps {
  mode: uploadModes;
  fileList: any[];
  setFileList: React.Dispatch<React.SetStateAction<any[]>>;
  onUploadClicked: (fileList: UploadFile[]) => void;
  disabled?: boolean;
}
export const FileListItem = ({
  name,
  actionIcon,
}: {
  name: string;
  actionIcon: ReactElement;
}): JSX.Element => {
  return (
    <div className="upload-fileList-item">
      <span>
        <img src={recipeIcon} className="document-icon" />
        {name}
      </span>
      {actionIcon}
    </div>
  );
};

const getFilesExtension = (mode: uploadModes) => {
  switch (mode) {
    case "Config":
      return "";
    case "Recipe":
      return ".rcp";
    case "Software":
      return ".zip";
  }
};

const UploadButton: React.FC<UploadButtonProps> = (props) => {
  const { fileList, setFileList, mode } = props;
  const uploadProps = {
    name: "file",
    fileList: fileList,
    maxCount: 1,
    accept: getFilesExtension(mode),
    // beforeUplod returns false to stop default
    // action: string,
    beforeUpload: () => false,
    onChange: (info: any) => {
      if (info.file.name.length > 26) {
        const index = info.fileList.findIndex(
          (e: any) => e.uid === info.file.uid
        );
        info.fileList.splice(index, 1);
        message.error({
          content: (
            <span>
              File name should be <b>26 characters or less</b> including the 4
              characters <b>.rcp</b>
            </span>
          ),
          duration: 15,
          style: {
            marginTop: "5vh",
            fontSize: 18,
            lineHeight: "24px",
          },
        });
      }
      setFileList(info.fileList);
    },
  };
  const uploadFiles = () => {
    props.onUploadClicked(fileList);
  };
  const removeFile = (index: number) => {
    const files = _.cloneDeep(fileList);
    files.splice(index, 1);
    setFileList(files);
  };
  return (
    <>
      <Col xs={24} sm={24} md={24} lg={24} style={{ fontSize: 24 }}>
        {(fileList.length < 1 ? "Choose " : "") + "File"}
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} style={{ height: 150 }}>
        {fileList.length < 1 ? (
          <Dragger {...uploadProps}>
            <p
              className={"ant-upload-drag-icon "}
              style={fileList.length > 0 ? {} : {}}
            >
              <InboxOutlined size={48} />
            </p>
            <p className="ant-upload-text">
              <b>Click or drag the file you want to upload</b>
            </p>
          </Dragger>
        ) : (
          fileList.map((e: UploadFile, index) => (
            <FileListItem
              key={index}
              actionIcon={
                <DeleteOutlined
                  className="delete-icon"
                  onClick={() => removeFile(index)}
                />
              }
              name={e.name}
            />
          ))
        )}
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} className="btn-grp">
        <Button
          type="primary"
          disabled={props.disabled || fileList.length < 1}
          onClick={() => uploadFiles()}
        >
          Upload
        </Button>
      </Col>
    </>
  );
};

export default UploadButton;
