import { Button, Modal } from "antd";
import { ButtonType } from "antd/lib/button";
import React, { useState } from "react";

import "./styles.less";

interface Props {
  buttonText: string;
  buttonType?: ButtonType;
  warningText?: string;
  secondaryText?: string;
  okText?: string;
  cancelText?: string;
  onOk: () => void;
  style?: React.CSSProperties;
}

const ConfirmButton: React.FC<Props> = ({
  buttonText,
  buttonType,
  warningText,
  secondaryText,
  okText,
  cancelText,
  onOk,
  style,
}) => {
  const [open, setOpen] = useState(false);
  const onOkClicked = () => {
    onOk();
    setOpen(false);
  };
  return (
    <>
      <Button
        style={style}
        onClick={() => setOpen(true)}
        type={buttonType ?? "default"}
      >
        {buttonText}
      </Button>
      <Modal
        className="confirm-modal"
        closable={false}
        visible={open}
        footer={null}
        centered
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <span className="confirm-warning-txt">
            {warningText ?? "Are you sure to continue ?"}
          </span>
          <span className="confirm-sec-txt">{secondaryText ?? ""}</span>
          <br></br>
          <div>
            <Button type="default" onClick={() => onOkClicked()}>
              {okText ?? "Continue"}
            </Button>
            <Button type="default" onClick={() => setOpen(false)}>
              {cancelText ?? "Cancel"}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmButton;
