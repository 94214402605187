import { UploadFile } from "antd/lib/upload/interface";
import { selection } from "../components/common/dropDowns";
import { OvenInput, OvenInput2, RecentFile, store } from "../config/types";
import { msalInstance } from "../index";
import { DropdownResponse } from "./responses";

const urlPath =  process.env.NODE_ENV === "production" ? 'https://hatco-ovention-func-prod-001.azurewebsites.net/api/':
                                'http://localhost:7071/api'

export const getIdToken = async ():Promise<string> => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    // msalInstance.loginRedirect();
    throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
  }
  const response = await msalInstance.acquireTokenSilent({
    scopes:["openid"],
    account: account,
});
  return response.idToken;
};

export const getUserDetails= async ():Promise<any>=>{
  const idToken = await getIdToken()
  const headers = new Headers();
  const bearer = `Bearer ${idToken}`;
  headers.append("Authorization", bearer);
  const options = {
    method: "GET",
    headers: headers
  };
  
  return fetch(urlPath+'/userdetails', options)
  .then(response => {
    if (response.ok)
      return response.json()
    else
      return response.json().then(err => Promise.reject(err))
  })
  .catch(err=> { throw new Error(err.message) });
}


export const getDropdownData = async(orgId?:number, signal?: any):Promise<DropdownResponse> => {
  const idToken = await getIdToken()
  const headers = new Headers();
  const bearer = `Bearer ${idToken}`;
  headers.append("Authorization", bearer);
  const options = {
    method: "GET",
    headers: headers,
  };

  const path = (orgId === 0 ? urlPath + '/dropdown' : urlPath + '/dropdown?orgId=' + orgId)
  return fetch(path, options)
  .then(response => {
    if (response.ok)
      return response.json()
    else
      return response.json().then(err => Promise.reject(err))
  })
  .catch(err=> {throw new Error(err)});
}

export const getStores = async(params:selection, signal?: any):Promise<any> => {
  const idToken = await getIdToken()
  const headers = new Headers();
  const bearer = `Bearer ${idToken}`;
  headers.append("Authorization", bearer);
  const options = {
    method: "GET",
    headers: headers,
    signal: signal
  };
  let query='?'
  query=query+'orgId='+params.org+'&'
  if (params.state.length >0)
    query+='state='+params.state.join("_")+'&'
  if (params.city.length >0)
    query+='city='+params.city.join("_") 
  
    
  const path =urlPath + '/stores'+query
  return fetch(path, options)
  .then(response => {
    if (response.ok)
      return response.json()
    else
      return response.json().then(err => Promise.reject(err))
  })
  .catch(error =>
    {
      if (signal.aborted)
        throw new Error('AbortError')
      else
        throw new Error(error.message)
    });
}

export const getOvenDetails = async(ovenId:string, signal?: any):Promise<{oven:OvenInput|OvenInput2,store:store}> => {
 
  const idToken = await getIdToken()
  const headers = new Headers();
  const bearer = `Bearer ${idToken}`;
  headers.append("Authorization", bearer);
  const options = {
    method: "GET",
    headers: headers,
    signal: signal
  };
      
  const path =urlPath + '/oven/'+ovenId
  return fetch(path, options)
  .then(response => {
    if (response.ok)
      return response.json()
    else
      return response.json().then(err => Promise.reject(err))
  })
  .catch(error =>
    {
      if (signal.aborted)
        throw new Error('AbortError')
      else
        throw new Error(error.message)
    });
}

export const editOvenDetails = async(ovenId:string,ovenInput: OvenInput | OvenInput2):Promise<any> => {
 
  const idToken = await getIdToken()
  const headers = new Headers();
  const bearer = `Bearer ${idToken}`;
  headers.append("Authorization", bearer);
  const options = {
    method: "PATCH",
    headers: headers,
    body: JSON.stringify(ovenInput)
  };
      
  const path =urlPath + '/oven/'+ovenId
  return fetch(path, options)
  .then(response => {
    if (response.ok)
      return response.text()
    else
      return response.json().then(err => Promise.reject(err))
  })
  .catch(error =>{
        throw new Error(error.message)
    });
}

export const deleteOven = async(ovenId:string):Promise<any> => {
 
  const idToken = await getIdToken()
  const headers = new Headers();
  const bearer = `Bearer ${idToken}`;
  headers.append("Authorization", bearer);
  const options = {
    method: "DELETE",
    headers: headers
  };
      
  const path =urlPath + '/oven/'+ovenId
  return fetch(path, options)
  .then(response => {
    if (response.ok)
      return response.text()
    else
      return response.json().then(err => Promise.reject(err))
  })
  .catch(error =>{
        throw new Error(error.message)
    });
}

export const getOvens = async(params:selection, signal?: any):Promise<any> => {
  // /ovens?orgId=1&state=Illinois_Texas&city=Frisco,Texas_Chicago,Illinois&store=guid_guid
  const idToken = await getIdToken()
  const headers = new Headers();
  const bearer = `Bearer ${idToken}`;
  headers.append("Authorization", bearer);
  const options = {
    method: "GET",
    headers: headers,
    signal: signal
  };
  let query='?'
  query += 'orgId=' + params.org + '&'
  if (params.state.length > 0)
    query += 'state=' + params.state.join("_") + '&'
  if (params.city.length > 0)
    query += 'city=' + params.city.join("_") + '&'
    if (params.store.length > 0)
    query += 'storeId=' + params.store.join("_") 
  
    
  const path =urlPath + '/ovens'+query
  return fetch(path, options)
  .then(response => {
    if (response.ok)
      return response.json()
    else
      return response.json().then(err => Promise.reject(err))
  })
  .catch(error =>
    {
      if (signal.aborted)
        throw new Error('AbortError')
      else
        throw new Error(error.message)
    });
}

export const uploadRecipe = async (
  fileList: UploadFile[],
  orgId: number,
  selectedOvens: string[],
  type: string
  ):Promise<any> => {
    const idToken = await getIdToken();
    const headers = new Headers();
    const bearer = `Bearer ${idToken}`;
    headers.append("Authorization", bearer);

    const formData = new FormData();
    formData.append("orgId", orgId.toString());
    formData.append("ovens", selectedOvens.toString());
    formData.append("type", type);

    const recentFiles:any[]=[]

    fileList.forEach((e) => {
      e.originFileObj?
      formData.append("file", e.originFileObj as File):
      recentFiles.push(e)
    });
    formData.append('recentFile',JSON.stringify(recentFiles))
    
    const options = {
      method: "POST",
      headers: headers,
      body: formData,
    };
    const path =urlPath + '/uploadfile'

    return fetch(path, options)
    .then(response => {
      if (response.ok)
        return response.json()
      else
        return response.json().then(err => Promise.reject(err))
    })
    .catch(err=> {throw new Error(err.message)});
};

export const getRecentFiles = async(type:string,orgId?:number, signal?: any):Promise<RecentFile[]> => {
  const idToken = await getIdToken()
  const headers = new Headers();
  const bearer = `Bearer ${idToken}`;
  headers.append("Authorization", bearer);
  const options = {
    method: "GET",
    headers: headers,
  };

  let query='?'
  query += 'type=' +  type
  query+= '&orgId=' + orgId

  const path = urlPath +'/recentFiles'+ query

  return fetch(path, options)
  .then(response => {
    if (response.ok)
      return response.json()
    else
      return response.json().then(err => Promise.reject(err))
  })
  .catch(err=> {throw new Error(err.message)});
}

export const createOven = async (ovenInput: OvenInput | OvenInput2
  ):Promise<[[string,string]]> => {
    const idToken = await getIdToken();
    const headers = new Headers();
    const bearer = `Bearer ${idToken}`;
    headers.append("Authorization", bearer);
    
    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(ovenInput),
    };
    const path =urlPath + '/oven'

    return fetch(path, options)
    .then(response => {
      if (response.ok)
        return response.json()
      else
        return response.json().then(err => Promise.reject(err))
    })
    .catch(err=> {throw new Error(err.message)});
};

export const getOvenConfig = async (serialNo: string, model: string | undefined):Promise<[[string,string]]> => {
    const idToken = await getIdToken();
    const headers = new Headers();
    const bearer = `Bearer ${idToken}`;
    headers.append("Authorization", bearer);
    
    const input = {model: model, serialNo: serialNo, isConfig: true}
    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(input),
    };
    const path =urlPath + '/oven'

    return fetch(path, options)
    .then(response => {
      if (response.ok)
        return response.json()
      else
        return response.json().then(err => Promise.reject(err))
    })
    .catch(err=> {throw new Error(err.message)});
};

export const editUser = async(userData:any, ):Promise<any> => {
  const idToken = await getIdToken()
  const headers = new Headers();
  const bearer = `Bearer ${idToken}`;
  headers.append("Authorization", bearer);

  const options = {
    method: "PATCH",
    headers: headers,
    body:JSON.stringify(userData)
  }
  
    
  const path =urlPath + '/user'
  return fetch(path, options)
  .then(response => {
    if (response.ok)
      return response.text()
    else
      return response.json().then(err => Promise.reject(err))
  })
  .catch(error =>  {throw new Error(error.message)});
}
export const updateProfilePicture = async (picture: any):Promise<any> => {
    const idToken = await getIdToken();
    const headers = new Headers();
    const bearer = `Bearer ${idToken}`;
    headers.append("Authorization", bearer);
    
    const formData = new FormData();
    formData.append("remove", picture as string)
    let options = {
      method: "POST",
      headers: headers,
      body: formData,
    };
    if (picture !== 'remove'){
      formData.append("file", picture as File)
  
      options = {
        method: "POST",
        headers: headers,
        body: formData,
      };
    }

    const path =urlPath + '/updatePicture'

    return fetch(path, options)
    .then(response => {
      if (response.ok)
        return response.text()
      else
        return response.json().then(err => Promise.reject(err))
    })
    .catch(err=> {throw new Error(err.message)});
};

export const updateLocation = async (locationData: any):Promise<any> => {
  const idToken = await getIdToken();
  const headers = new Headers();
  const bearer = `Bearer ${idToken}`;
  headers.append("Authorization", bearer);
  
  let options = {
    method: "PATCH",
    headers: headers,
    body: "remove",
  };
  if (locationData !== 'remove'){
    options = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(locationData),
    };
  }

  const path =urlPath + '/stores'
  return fetch(path, options)
  .then(response => {
    if (response.ok)
      return response.text()
    else
      return response.json().then(err => Promise.reject(err))
  })
  .catch(err=> {throw new Error(err.message)});
};
