import { Select } from "antd";
import { dropDownItem } from "../../config/types";
const { Option } = Select;

const SingleDropdown = ({
  label = "",
  value,
  valueType = "",
  options,
  disabled,
  allowClear,
  onChange,
  isLoading,
}: {
  label?: string;
  value: number | string;
  valueType?: string;
  options: dropDownItem[];
  disabled?: boolean;
  allowClear?: boolean;
  onChange(id: number | string, valueType: string): void;
  isLoading?: boolean;
}): JSX.Element => {
  const optionTags: Array<JSX.Element> = [];

  for (let i = 0; i < options.length; i++) {
    optionTags.push(
      <Option key={i} value={options[i].id} className="dropdown-text">
        {options[i].name}
      </Option>
    );
  }
  return (
    <>
      {label ? <div className="dropdown-title">{label}</div> : ""}
      <Select
        loading={isLoading}
        allowClear={allowClear}
        placeholder="Select options"
        value={value != 0 && value != "" ? value : undefined}
        style={{
          width: "100%",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          textAlign: "left",
        }}
        disabled={disabled}
        onChange={(value) => onChange(value, valueType)}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        optionFilterProp="children"
      >
        {optionTags}
      </Select>
    </>
  );
};

export default SingleDropdown;
