import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserDetails } from "../api/portalData";
import PSWrapper from "../components/common/psWrapper";
import Loader from "../components/utils/Loader";
import { SETTINGS_URL } from "../config/constants";

import "./styles/profile.less";

const Profile: React.FC<any> = () => {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState<any | null>(null);
  const getProfileDataCall = async () => {
    const result = await getUserDetails();
    const items = result.details;
    setProfileData({
      ...items,
      email: items.emails[0],
    });
  };
  useEffect(() => {
    getProfileDataCall();
  }, []);

  return (
    <PSWrapper
      title="My profile"
      buttonAction={() => navigate(SETTINGS_URL)}
      buttonTitle="Edit Information"
    >
      {profileData != null ? (
        <Row>
          <Col xs={24} md={8} lg={6}>
            <img
              className="profile-picture"
              src={profileData.imgUrl || undefined}
            />
          </Col>
          <Col xs={24} md={16} lg={12}>
            <Row className="profile-username">{profileData?.name}</Row>
            <Row className="profile-row">
              <span className="field-name">Company</span>
              <span className="field-value">
                {profileData.company ? profileData.company : "N/A"}
              </span>
            </Row>
            <Row className="profile-row">
              <span className="field-name">Phone</span>
              <span className="field-value">
                {profileData.phone ? profileData.phone : "N/A"}
              </span>
            </Row>
            <Row className="profile-row">
              <span className="field-name">Email</span>
              <span className="field-value">
                {profileData.email ? profileData.email : "N/A"}
              </span>
            </Row>
            <Row className="profile-row">
              <span className="field-name">Role</span>
              <span className="field-value">
                {profileData.role ? profileData.role : "N/A"}
              </span>
            </Row>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
    </PSWrapper>
  );
};
export default Profile;
