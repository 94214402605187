import React, { useContext } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { Col, Menu, Row } from "antd";

import { CloudUploadOutlined, MenuOutlined } from "@ant-design/icons";
import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import { useMsal } from "@azure/msal-react";

import {
  PROFILE_URL,
  SETTINGS_URL,
  UPLOAD_RECIPE_URL,
  UPLOAD_CONFIG_URL,
  UPLOAD_SOFTWARE_URL,
} from "../config/constants";
import oventionLogo from "../img/header-logo.png";
import RecipeIcon from "../img/recipe-icon.svg";
import SoftwareIcon from "../img/software-icon.svg";
import ConfigIcon from "../img/config-icon.svg";
import { useWindowDimensions } from "../components/common/windowDimensions";
import { context } from "./appLayout";
import ProfileMenu from "../components/common/profileMenu";

import "./styles/appLayout.less";

interface MenuItems {
  menu: string[];
  menuPath: string[];
}
interface MenuProps extends MenuItems {
  selectedKeys: [];
}

const actionButtonStyles = {
  font: "normal normal bold 16px/24px Questrial",
  color: "black",
  border: "2px solid #ff8a2c",
  borderRadius: "5px",
  backgroundColor: "#ff8a2c",
  height: "40px",
  width: "100px",
};

const mainButtonStyles = {
  backgroundColor: "transparent",
  color: "#ff8a2c",
  border: "2px solid #ff8a2c",
  borderRadius: "5px",
  height: "40px",
  width: "110px",
  justifyContent: "left",
  padding: "0 10px",
};
const actionItems: { icon: string; name: string; url: string }[] = [
  {
    icon: RecipeIcon,
    name: "Recipe",
    url: UPLOAD_RECIPE_URL,
  },
  {
    icon: SoftwareIcon,
    name: "Software",
    url: UPLOAD_SOFTWARE_URL,
  },
  {
    icon: ConfigIcon,
    name: "Config",
    url: UPLOAD_CONFIG_URL,
  },
];

const MenuComponent: React.FC<MenuProps> = (props) => {
  const role = useContext(context);
  const { instance } = useMsal();
  const navigate = useNavigate();
  const path = useLocation();
  const newMenuItems: MenuItems = {
    menu: props.menu,
    menuPath: props.menuPath,
  };
  const windowDimensions = useWindowDimensions();

  function handleLogout() {
    instance.logout();
  }
  const actions = (): JSX.Element[] => {
    return actionItems.map((item, index) => (
      <Action
        key={index}
        style={actionButtonStyles}
        onClick={() => navigate(item.url)}
      >
        <>
          <img src={item.icon} />
          <span style={{ transform: "none", marginLeft: 5 }}>{item.name}</span>
        </>
      </Action>
    ));
  };

  const menuItems = (mobile = false) => {
    return (
      <Menu
        theme="dark"
        mode="horizontal"
        overflowedIndicator={<MenuOutlined style={{ fontSize: 24 }} />}
        triggerSubMenuAction={"click"}
        selectedKeys={[props.menuPath.indexOf(path.pathname).toString()]}
      >
        {newMenuItems.menu.map((item, index: number) => {
          const key = index;
          return (
            <Menu.Item key={key}>
              <Link to={newMenuItems.menuPath[index]}>{item}</Link>
            </Menu.Item>
          );
        })}
        {mobile ? (
          <>
            <Menu.Item key={"profile"} onClick={() => navigate(PROFILE_URL)}>
              Profile
            </Menu.Item>
            <Menu.Item key={"settings"} onClick={() => navigate(SETTINGS_URL)}>
              Settings
            </Menu.Item>
            <Menu.Item key={"logout"} onClick={handleLogout}>
              Logout
            </Menu.Item>
          </>
        ) : (
          ""
        )}
      </Menu>
    );
  };

  const conditionalFab = () => {
    if (windowDimensions.width > 576) {
      return (
        <Fab
          mainButtonStyles={mainButtonStyles}
          style={{
            position: "absolute",
            top: -20,
            right: 80,
          }}
          icon={
            <>
              <CloudUploadOutlined />
              <span style={{ transform: "none", marginLeft: 10 }}>
                {"Upload"}
              </span>
            </>
          }
          event={"click"}
          alwaysShowTitle={true}
        >
          {actions()}
        </Fab>
      );
    } else {
      return (
        <Fab
          mainButtonStyles={{
            ...mainButtonStyles,
            width: 32,
            height: 32,
            backgroundColor: "#414142",
            boxShadow: "none",
            padding: "0 6px",
          }}
          style={{
            position: "absolute",
            top: -20,
            right: -5,
          }}
          icon={<CloudUploadOutlined style={{ fontSize: 16 }} />}
          event={"click"}
          alwaysShowTitle={true}
        >
          {actions()}
        </Fab>
      );
    }
  };
  const conditionalMenu = () => {
    if (windowDimensions.width <= 576) {
      return (
        <Row style={{ flexWrap: "nowrap" }}>
          <Col xs={8}>{menuItems(true)}</Col>
          <Col>
            <img src={oventionLogo} alt="" className="logo" />
          </Col>
        </Row>
      );
    } else {
      return (
        <>
          <img src={oventionLogo} alt="" className="logo" />
          <div className="settings">
            <ProfileMenu logout={() => handleLogout()} />
          </div>
          {menuItems()}
        </>
      );
    }
  };

  return (
    <>
      {conditionalMenu()}
      {role != "manager" ? conditionalFab() : ""}
    </>
  );
};

export default MenuComponent;
