// Paths
export const UPLOAD_RECIPE_URL = '/upload/recipe'
export const UPLOAD_CONFIG_URL = '/upload/config'
export const UPLOAD_SOFTWARE_URL = '/upload/software'
export const CREATE_OVEN_URL = '/oven/new'
export const EDIT_OVEN_URL = '/oven/:ovenId/edit'
export const PROFILE_URL = '/profile'
export const SETTINGS_URL = '/settings'

// Oven Model DropDown
export const OVEN_MODELS = ['MiSA-a12','MiLO-14','MiLO-16']
export const DOUBLE_OVEN_MODELS = ['MiLO2-16-G2']
