import { useNavigate } from "react-router-dom";
import backArrowLogo from "../../img/arrow-ios-back-outline.svg";

import "./styles.less";

interface backButtonProps {
  onBack?: () => void;
  style?: React.CSSProperties;
}

type Props = backButtonProps;

const BackButton: React.FC<Props> = ({ onBack, style }) => {
  const navigate = useNavigate();
  return (
    <div
      className="title-back-btn"
      onClick={onBack ?? (() => navigate(-1))}
      style={style}
    >
      <img className="arrow-back-button" src={backArrowLogo} />
      <b className="hide-mobile">Back</b>
    </div>
  );
};

export default BackButton;
