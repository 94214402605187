import { useContext } from "react";
import { Link } from "react-router-dom";
import { context } from "../../pages/appLayout";
import _ from "lodash";

import { Button, Col, Row } from "antd";
import ErrorTable from "./errorTable";
import { getAddressString } from "../store/storeCard";

import { errorData, oven, store } from "../../config/types";
import {
  UPLOAD_CONFIG_URL,
  UPLOAD_RECIPE_URL,
  UPLOAD_SOFTWARE_URL,
} from "../../config/constants";

import UpdateIcon from "../../img/alert-circle-blue.svg";

import "./styles.less";
import { useWindowDimensions } from "../common/windowDimensions";
import BackButton from "../common/backButton";

const displayNames = [
  ["Error", ["errors"]],
  ["Cooks", ["cooks"]],
  ["Hours of Operation", ["hoursOperational"]],
  ["Last recipe upload", ["key"]],
  ["Last software update", ["key"]],
  ["Last config. update", ["key"]],
];
interface OvenDetailProps {
  storeData: store;
  ovenData: oven;
}
const OvenDetails: React.FC<OvenDetailProps> = (props) => {
  const role = useContext(context);
  const windowDimensions = useWindowDimensions();
  const width = windowDimensions.width;

  const { storeData, ovenData } = props;

  const uploadPageState = {
    selectedOvens: [ovenData.id],
    store: [storeData.id],
  };

  return (
    <>
      <Row gutter={[{ xs: 0, lg: 16 }, 12]}>
        {width > 576 && role != "manager" ? (
          <Col style={{ position: "absolute", right: 0, top: 10, zIndex: 100 }}>
            <Button type="primary">
              <Link
                to={`edit`}
                state={{
                  ovenData: [ovenData],
                  storeData: [storeData],
                }}
              >
                Edit Location
              </Link>
            </Button>
          </Col>
        ) : (
          <></>
        )}

        <Col
          xs={24}
          sm={24}
          lg={24}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderBottom: "1px solid black",
          }}
        >
          <span className="od-store-name-wrapper">
            {width < 576 ? <BackButton /> : ""}
            <h1 className="od-store-name">{storeData.name}</h1>
          </span>

          <span className="od-store-details">
            <b>{`Address : `}</b>
            {getAddressString(storeData)}
          </span>
          <span className="od-store-details">
            <b>{`Number of Ovens : `}</b>
            {storeData.ovens}
          </span>
          <br />
        </Col>
        <Col
          xs={24}
          sm={24}
          lg={24}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <h2
            style={{ fontWeight: 600 }}
            className="hide-mobile"
          >{`Oven : ${ovenData.name}`}</h2>
          {props.children}
        </Col>
        <Col
          style={{
            fontSize: 18,
            margin: "0 auto",
          }}
          xs={24}
          sm={24}
          md={12}
          lg={9}
          className="oven-params"
        >
          {displayNames.map((e, index) => (
            <div key={index}>
              <span style={{ float: "left" }}>{`${e[0]} :`}</span>
              <span style={{ float: "right" }}>
                {_.get(props.ovenData, e[1], "N/A")}
              </span>
            </div>
          ))}
        </Col>
        {ovenData.errors > 0 ? (
          <Col xs={24} sm={24} md={24} lg={15}>
            <ErrorTable
              data={
                ovenData.data?.undismissedErrors?.map(
                  (e: Omit<errorData, "key">, index: number) => ({
                    ...e,
                    key: index,
                  })
                ) ?? []
              }
            />
          </Col>
        ) : (
          ""
        )}
        {role != "manager" ? (
          <Col xs={24} sm={24} lg={24} className="btn-grp">
            <Button type="default">
              <Link to={UPLOAD_RECIPE_URL} state={uploadPageState}>
                Add Recipe
              </Link>
            </Button>
            <Button type="default">
              <Link to={UPLOAD_SOFTWARE_URL} state={uploadPageState}>
                {ovenData.updates ? (
                  <img className="od-update-icon" src={UpdateIcon}></img>
                ) : (
                  ""
                )}
                Update Software
              </Link>
            </Button>
            <Button type="default">
              <Link to={UPLOAD_CONFIG_URL} state={uploadPageState}>
                Update Configuration
              </Link>
            </Button>
            {width < 576 ? (
              <Button type="primary">
                <Link
                  to={`edit`}
                  state={{
                    ovenData: [ovenData],
                    storeData: [storeData],
                  }}
                >
                  Edit Location
                </Link>
              </Button>
            ) : (
              <></>
            )}
          </Col>
        ) : (
          ""
        )}
      </Row>
    </>
  );
};

export default OvenDetails;
